<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Daftar Obat dan Alkes</b> yang ada di {{clinic_name}}
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/medicine/add')"
        v-if="btn"
      >Tambah</b-button>
      <!-- <b-button
        squared
        variant="outline-primary"
        class="mx-1"
        @click="$router.push('/medicine/import')"
      >
        <i class="fas fa-file-excel pr-1"></i>
        Unggah Excel</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose = 'master' :isAbjad ="true" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Card from "@/view/content/Card.vue";
import Table from '@/component/medicine/Table.vue'

export default {

  name: "List",

  components: {
    Card,
    Table
  },

  data() {
    return {
      btn: false,
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    }
  },

  methods: {

    setActiveButton() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btn = true
        }
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Obat dan Alkes" },
      { title: "Daftar Obat dan Alkes" },
    ])
    this.setActiveButton()
  },

}

</script>